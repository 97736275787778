import React from 'react'
import Layout from '../components/layout'
import Scorm from '../components/categories/scorm'
import Seo from '../components/seo'

const ScormPage = ({ location }) => {
  return (
    <Layout location={location}>
      <Scorm />
    </Layout>
  )
}

export const Head = ({ location }) => {
  const title = 'Top Scorm Freelancers | Codestaff'
  const description =
    'Hire the best Scorm freelancers at Codestaff. Get the top 1% of Scorm professionals to join your team.'

  return <Seo type="page" title={title} description={description} />
}

export default ScormPage
