import React from 'react';
import { Link } from 'gatsby';
import CxSearch from './cx-search'
import TopTrendingSkills from '../top-trending-skills';
import HireAndApply from '../hire-and-apply';
import Helmet from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"

import styled from 'styled-components'

const MainWrapper = styled.div`
margin: 0;
`

const faqMeta1 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
        "@type": "Question",
        "name": "What Is The Meaning Of SCORM?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "SCORM (Sharable Content Object Reference Model) is a set of technical standards for e-learning software products. SCORM lets programmers know how to write code so it can play well with other e-learning software because it’s the industry standard for e-learning interoperability."
        }
    }
}

const faqMeta2 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
        "@type": "Question",
        "name": "Why Is SCORM Important?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "SCORM is essential for successful e-learning delivery. It helps your e-learning platform and course content to understand each other and work perfectly together. Codestaff can help you set it all up."
        }
    }
}

const Scorm = () => (
    <MainWrapper>
        <Helmet>
            <script type="application/ld+json">{JSON.stringify(faqMeta1)}</script>
            <script type="application/ld+json">{JSON.stringify(faqMeta2)}</script>
        </Helmet>
        <main>
            <section className="cx_catagories">
                <div className="container-fluid cx_top">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-sm-10">
                                <h2>
                                    Hire top freelance<br /><span> SCORM Experts.</span>
                                </h2>
                                <p>
                                    Codestaff is a marketplace for top SCORM Experts. Top
                                    companies and start-ups choose Codestaff SCORM professionals
                                    for their mission critical software projects.
                            </p>
                                <Link to="/hire/" className="skills-banner-btn">
                                    Hire a top SCORM Expert now
                                <StaticImage src='../../images/arrow.png' alt='arrow' className="img-fluid" style={{ margin: '0 1rem' }} />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <CxSearch title='HIRE FREELANCE SCORM EXPERTS' banner='/scorm.png' bannerAlt='scorm banner' />
            </section>
            <HireAndApply />
            <TopTrendingSkills />
        </main>
    </MainWrapper>)

export default Scorm